import React from "react";

type IContent = {
  children: React.ReactNode;
};

const Content: React.FC<IContent> = ({ children }) => {
  return (
    <main className="overflow-y-auto overflow-x-hidden w-full px-6 lg:px-12 pt-6">
      {children}
    </main>
  );
};

export default Content;
