import React from "react";

// components
import Service from "./Service";

// constants
import dashboard from "../../../constants/dashboard";

// router
import { useNavigate } from "react-router-dom";

// state
import { get_services } from "../../../service/api/service";
import { useAppDispatch, useAppSelector } from "../../../store";

const RegisteredUser = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const page = useAppSelector(({ service }) => service.current_page);
  const services = useAppSelector(({ service }) =>
    service.services?.slice(0, 10)
  );

  const onLoad = React.useCallback(() => {
    get_services(dispatch, page);
  }, [dispatch, page]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="grid gap-6 grid-rows-[30%,10%,1fr] grid-rows-[20%, 1fr] lg:grid-rows-[25%, 1fr] h-full overflow-x-hidden overflow-y-auto">
      <div className="w-full h-full bg-primary-500 flex justify-between rounded-2xl shadow-lg p-6">
        {dashboard.dasboard.map((item) => (
          <div
            className="flex gap-3 items-center bg-white px-6 py-3 rounded-xl cursor-pointer hover:bg-primary-100 hover:text-white transition-colors duration-300"
            key={item.key}
          >
            <img
              alt="document"
              src={item.icon}
              onClick={() => navigate(item.link)}
              className="w-[2.5rem] h-[2.5rem] rounded-full shadow-md"
            />
            <p
              onClick={() => navigate(item.link)}
              className="text-center text-primary-700 font-bold text-[.9rem]"
            >
              {item.label}
            </p>
          </div>
        ))}
      </div>

      <div className="flex overflow-y-hidden overflow-x-auto justify-start items-center w-full">
        {dashboard.links.map((item, _) => (
          <div
            key={_}
            onClick={() => navigate(item.link)}
            className="text-[17px] bg-primary-500 md:text-[20px] whitespace-nowrap mr-3 block px-5 rounded-lg py-2 text-[white] cursor-pointer"
          >
            {item.name}
          </div>
        ))}
      </div>

      <div className="service-container flex gap-6 overflow-x-auto snap-x snap-mandatory">
        {services?.map((item) => (
          <Service
            key={item.id}
            item={item}
            className="snap-center flex-shrink-0"
          />
        ))}
      </div>
    </div>
  );
};

export default RegisteredUser;
