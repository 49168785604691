import React from "react";

// constants and icons
import { MdKeyboardBackspace } from "react-icons/md";
import { CiLogout } from "react-icons/ci";
import c_avatar from "../../../assets/images/statics/user.png";
import { GiHamburgerMenu } from "react-icons/gi";

// state
import { get_profile, logout } from "../../../service/api/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";

type INavigationBar = {
  onSidebar: () => void;
};

const NavigationBar: React.FC<INavigationBar> = ({ onSidebar }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const user = useAppSelector(({ auth }) => auth.user);
  const navbar = useAppSelector(({ navbar }) => navbar);
  const navigate = useNavigate();

  const onLoad = React.useCallback(() => {
    get_profile(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const avatar = user?.profile?.avatar || c_avatar;

  return (
    <header className="overflow-hidden flex items-center bg-white">
      <div className="w-[60%] text-[20px] font-bold flex justify-start">
        <div className="flex items-center md:mx-3">
          {location.pathname !== "/dashboard" && (
            <MdKeyboardBackspace
              size={30}
              color="#67C748"
              className="cursor-pointer hidden sm:block"
              onClick={() => navigate(-1)}
            />
          )}
          <GiHamburgerMenu
            size={25}
            className="sm:hidden cursor-pointer"
            onClick={onSidebar}
          />
        </div>

        <div className="mx-2 text-primary-500 ">{navbar?.title}</div>
      </div>

      <div className="w-[40%] text-right flex md:overflow-hidden items-center justify-end">
        <img
          src={avatar}
          alt="Profile"
          className="h-[2.5rem] w-[2.5rem] rounded-full border mr-2 overflow-hidden"
        />

        <p className="md:text-[15px] md:text-[#67C748] hidden md:block">
          {user?.profile?.name}
        </p>

        <div className="md:mx-2">
          <CiLogout
            title="Logout"
            size={25}
            color="red"
            className="cursor-pointer"
            onClick={() => logout(navigate)}
          />
        </div>
      </div>
    </header>
  );
};

export default NavigationBar;
